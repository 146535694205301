.deals-container {
    header {   
    min-height: unset;
    }
}

.deal-content-wrapper {
    padding: 0 20px;
    height: 80%;
}

.notification-box {
    text-align: center;
    width: 50%;
    margin: auto;
    background: #DBBE82;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    border-radius: 5px;
}