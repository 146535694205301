.applicationScreenInputAdmin {
  margin-bottom: 1.25rem;
  position: relative;
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #a0a0a0;
  }
  .form-control {
    height: 38px;
    width: 327px;
    font-size: 12px;
    // background-color: var(--black-bg) !important;
    //   color: #fff !important;
    &.active {
      background-color: #fff;
    }
  }
  .form-label {
    width: 100%;
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #a0a0a0;
  }
  .phone-label {
    margin-left: -62px;
  }
  .phone-label-store {
    margin-left: -70px;
  }
  .d-block {
    font-size: 9px;
    margin-top: 0;
  }
}
