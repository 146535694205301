table {
    border-spacing: 0;
    width: 100%;
    background-color: var(--color-text-dark);
    border-radius: 24px;
    color: var(--color-text-light);
    font-family: "Archivo", sans-serif;

    // text-transform: capitalize;
    thead {
        background-color: var(--color-text-light);
        // border-radius: 24px;
        font-size: 12px;
        color: var(--color-text-dark);
        font-weight: 400;

        tr {
            height: 40px;
            // border-radius: 50px !important;
        }

        th {
            padding: 12px;
            // text-align: center;
        }

        th:first-child {
            border-radius: 24px 0 0 0;
        }

        th:last-child {
            border-radius: 0 24px 0 0;
        }
    }

    td {
        padding: 12px;
        // text-align: center;
    }
}

.table-fixed-head {
    pointer-events: none;
}

.TableScrollbar {
    border-radius: 24px !important;
    scroll-behavior: smooth;
}

.custom-order-table {
    height: 35vh;
    overflow-x: hidden;
    position: relative;
    border: 1px solid lightgray;
}

.order-list {
    tbody {
        tr {
            td {
                padding: 20px;
            }
        } tr:hover{
            background-color: lightgray;
            cursor: pointer;
        }
    }
}

.table-wrapper {
    overflow: auto !important;
}

.orders-table .table-wrapper {
    border: 1px solid #d3d3d3!important;
    max-height: 35vh;
    overflow-x: hidden!important;
    overflow-y: auto!important;
    padding: 0 0 20px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

table {
    thead {
        position: sticky;
        top: 0;
    }
}