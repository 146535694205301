.list-items {
    .nav-pills .nav-link {
        background: none;
        border: 0;
        color: #343434;
    }
    .nav-pills .nav-link.active {
        background-color: #EFEFEF !important;
        color: #343434;
    }
}
.items {
    .nav-pills .nav-link {
        background: none;
        border: 0;
        color: #343434;
    }
    .nav-pills .nav-link.active {
        background-color: #EFEFEF !important;
        color: #343434;
    }
}

.add-item {
    .saveChanges {
        background-color: #DBBE82 !important;
        color: #343434;
        border: none;
        outline: none;
    }
}

.border-end {
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
}