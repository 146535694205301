@import "../../../assets/styles/mixins.scss";

.has-horzontal-scroll {
    overflow-x: auto;
}

.font-14 {
    font-size: 14px;
}

.font-10 {
    font-size: 10px;
}

.dashboard-main {
    .head {
        height: unset;

        .hello-user-card {
            .hello-user-card__info {
                span {
                    line-height: 14px;
                }
            }
        }

        .available-balance-head {}

        .balance-due {
            width: 200px;

            span {
                &.due-amount {
                    font-weight: 700;
                }
            }
        }

        .dashboard-options {
            bottom: -32px;

            .dashboard-legend {
                padding: 8px;
                height: 65px;
                width: 85px;
                border-radius: 25px;
                font-weight: 800;
                box-shadow: var(--legend-dropshadow);
            }
        }
    }

    .dashboard-info-card {
        border-radius: 25px;
        margin-left: 16px;
        height: auto;

        &>* {
            width: 260px;
        }

        .info-head {
            border: #1EB4FF 1px solid;
            border-radius: 17px 17px 0px 0px;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            padding-left: 16px
        }

        .info {
            background: var(--var-blue-gradient-bg);
            height: 125px;
            border-radius: 0px 0px 14px 14px;
            height: 64px;
        }
    }

}

.table {
    background-color: var(--color-text-dark);
    border-radius: 24px;
    font-size: 12px;
    color: var(--color-text-light);


}

.table-responsive {
    border-radius: 24px;
}

.store-logo {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin: 5px;

}

.createIcon {
    background-color: var(--color-text-light) !important;
}

.stock-qty-actions {
    display: flex;
    border: 1px solid lightgray;
    margin: 0;
    text-align: center;
    max-width: 70px;
    border-radius: 7px;

    .inc-icon,
    .dec-icon {
        padding: 0 3px;
        cursor: pointer;
        user-select: none;
    }

    .input-box {
        input {
            width: 24px;
            border: none;
            text-align: center;

            &:focus-visible {
                outline: none;
            }
        }
    }
}

.inventory-filters {
    .btn {
        padding: 2px 0.75rem !important;
    }
}

.category-selection-inventory {
    display: flex;

    button {
        border-bottom-right-radius: unset;
        border-bottom-left-radius: unset;
        border-top-left-radius: 27px;
        border-top-right-radius: 27px;
        background: #343434;
        border-color: #343434;
        color: #DBBE82;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 12px;
        padding: 15px;
    }

    button:hover,
    button:focus-visible {
        background: #DBBE82;
        border-color: #DBBE82;
        color: #343434
    }
}

.inventory {
    table thead th:first-child {
        border-radius: 0;
    }
    table {
        .stock-qty-actions {
            input {
                width: 40px;
            }
        }
    }
}

.restock-btn {
    transform: scale(1.4);
}
.restock-btn:hover, .delete-product-btn:hover {
    filter: invert(62%) sepia(82%) saturate(318%) hue-rotate(2deg) brightness(90%) contrast(87%);
}