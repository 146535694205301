@import "../../../assets/styles/mixins.scss";

.has-horzontal-scroll {
    overflow-x: auto;
}
.font-14 {
    font-size: 14px;
}
.font-10 {
    font-size: 10px;
}

.dashboard-main {
    .head {
        height: unset;
        .hello-user-card {
            .hello-user-card__info {
                span {
                    line-height: 14px;
                }
            }
        }
        .available-balance-head {
        }
        .balance-due {
            width: 200px;
            span {
                &.due-amount {
                    font-weight: 700;
                }
            }
        }
        .dashboard-options {
            bottom: -32px;
            .dashboard-legend {
                padding: 8px;
                height: 65px;
                width: 85px;
                border-radius: 25px;
                font-weight: 800;
                box-shadow: var(--legend-dropshadow);
            }
        }
    }
    .dashboard-info-card {
        border-radius: 25px;
        margin-left: 16px;
        height: auto;
        & > * {
            width: 260px;
        }
        .info-head {
            border: #1eb4ff 1px solid;
            border-radius: 17px 17px 0px 0px;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            padding-left: 16px;
        }
        .info {
            background: var(--var-blue-gradient-bg);
            height: 125px;
            border-radius: 0px 0px 14px 14px;
            height: 64px;
        }
    }
}

.table {
    background-color: var(--color-text-dark);
    border-radius: 24px;
    font-size: 12px;
    color: var(--color-text-light);
}
.table-responsive {
    border-radius: 24px;
}
.store-logo {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    margin: 5px;
}
.createIcon {
    background-color: var(--color-text-light) !important;
}

.has-stores-table {
    table {
        th:nth-child(1) {
            width: 18%;

        }
        th:nth-child(3),
        td:nth-child(3) {
            min-width: 140px;

            span {
                position: absolute;
            }
        }
        th:nth-child(5),
        td:nth-child(5) {
            min-width: 100px;
        }
    }
}
