$global-breakpoint-xs:  480px;
$global-breakpoint-sm:  768px;
$global-breakpoint-md:  992px;
$global-breakpoint-lg:  1200px;
$global-breakpoint-xl:  1440px;

:root {
  // light theme colors only
  --dark-theme-purple: #383ADB;
  --compos-light:      #393CDC;
  --color-headings:    #545FBD;
  --color-text-dark:   #FFF;
  --color-text-light:  #343434;
  --color-text-grey:   #7C7C7C;
  --color-light-blue: #363062;
  --bg-color: #efefef;
  --golden-color: #CB9F47;
  --text-color-over-grad:#ffffff;
  --text-color: var(--color-text-light);
  --text-color-over-white: var(--color-text-light);
  --heading-color-over-grad: #ffffff;
  --heading-color-over-plain : #545FBD;
  --button-primary-bg-plain: #CACACA;
  --bg-color-nav-footer:#fcfcfc;
  --nav-footer-selected:#388CE0;
  --red-message: #F14545;
  --secondary-bg: #e4e4e4;
  --grey-text: #595959;
  --bg-light-grey: #f6f6f6;

  --white-color: #ffffff;
  --faint-off-white-bg-color: #F7F7F7;
  --inactive-filter-button-bg-color: #EFEFEF;
  --inactive-filter-button-text-color: #bdbdbd;
  --hr-color:#bdbdbd;
  --social-login-bg: #F9F9F9;
  --bg-backdrop: rgba(38, 38, 38, 0.5);
  --bg-color-grey: #D3D3D3;
  --nav-bar-footer-height: 76px;
  --nav-bar-footer-active-offset: -42px;
  --dashboard-gutter: 28px;
  --dashboard-header-padding: 46px;
  --dashboard-header-drop-distance: 30px;
  --dashboard-header-drop-hpadding: 18px;

  --disabled-text-color: #6B6B6B;
  
  --color-setup-pin-digits: #404040;
  --color-setup-pin-keys: #767676;
  --var-blue-gradient-bg: linear-gradient(332.22deg, #5C52B3 -3.78%, #4478D0 53.87%, #1EB4FF 94.17%);
  --var-voilet-gradient-bg: linear-gradient(92.5deg, #3892DB -10.15%, #974ED6 60.41%, #D721D2 107.65%);

  --white-control-dropshadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  --list-item-subtext: #B3B3B3;

  --color-price-up: #61C277;
  --color-price-down: #E5202B;
  --legend-dropshadow: 0px 6px 32px rgba(0, 0, 0, 0.17);
  --dropdown-arrow-color: #a6a6a6;
  --collapsable-bg: #ffffff;
  --food-category-color:#393CDC;
  --transport-category-color:#CA23D3;
  --investment-category-color:#FFC700;
  --rent-category-color:#3C3D6C;
  --installment-category-color:#1EB4FF;
  --other-category-color:#EA3434;
  --gold-card-bg: linear-gradient(72.42deg, #EACD34 2.26%, #36350E 98.45%);
  --green-card-bg: linear-gradient(69.07deg, #34EA51 0%, #BBEA34 109.7%);
  --red-card-bg: linear-gradient(69.07deg, #EA3434 0%, #EA9634 109.7%);
  --black-card-bg: linear-gradient(74.24deg, #000000 2.32%, #6C6C6C 87.04%, #000000 87.04%, #000000 87.04%);
  --payment-rule-icon-color:#393cdc;
  --light-hr-color:#f6f6f6;
}
