@import "../../../../assets/styles/mixins.scss";

.vape-card{
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    border-radius: 32px;
    color:var(--text-color);
    padding: 42px;

    width: 84vw !important;
    max-width: 500px !important;

    @include mq($global-breakpoint-md) {
        display: flex;
        flex-direction: column;
        min-height: 400px;
        max-width: 600px;
        width: 100%;
        &.min-h-md-unset{
            min-height: unset;
        }
    }

    h2 {
        font-size: 24px;
        margin: 0 0 1.4em !important;
        line-height: 34px;
    }

    form {
        .submit-btn-wrapper {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          &::before {
              content: "";
              flex-grow: 1;
          }
        }
    }
}

.form-logo{
    height: 142px;
    @include mq($global-breakpoint-md) {
        max-width: 350px;
        width: 100%;
    }
}