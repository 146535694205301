.css-1fdsijx-ValueContainer {
  /*padding: 0 !important;*/
}
css-1fdsijx-ValueContainer {
  margin: 0;
  padding: 0;
}
.css-qbdosj-Input {
  padding: 0 !important;
  margin: 0 !important;
}
.css-13cymwt-control {
  min-height: 30px !important;
}
.css-1xc3v61-indicatorContainer {
  /*padding: 0 !important;*/
}

.filter {
  &__menu {
    margin: 0rem auto !important;
  }

  &__option {
    font-size: 14px !important;
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: none;
  }
}

.css-b62m3t-container {
  font-size: 14px !important;
}
