@import "../../assets//styles/mixins.scss";

.verify-modal-popup {
    max-width: 630px !important;
}

.verify-user-body {
    padding: 30px;

    .ask-question {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        align-items: center;
        color: #343434;
    }

    .user-details {
        display: flex;
        justify-content: space-between;
        margin: 30px 0px;

        .name-email {
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-family: 'Archivo';
            font-style: normal;
            font-size: 14px;
            line-height: 14px;
            color: #343434;

            .name {
                margin: 0;
                font-weight: 500;
            }

            .email {
                font-weight: 400;
            }
        }

        .role-phone {
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            font-family: 'Archivo';
            font-style: normal;
            font-size: 14px;
            line-height: 14px;

            .role {
                font-weight: 500;
                color: #CB9F47;
                text-transform: capitalize;
            }

            .phone {
                font-weight: 400;
                color: #343434;
            }
        }
    }

    .action-buttons {
        text-align: center;

        .cancel-btn {
            color: var(--golden-color);
            border-color: var(--golden-color);
        }

        .approve-btn {
            color: var(--color-text-dark);
            background-color: var(--color-text-light);
            margin-left: 30px;
        }

        button {
            width: 200px;
        }

    }
}