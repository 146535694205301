.field-entity {
    width: 100%;
    flex: 1 1 180px;
    margin-top: 10px;

    .field-label {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        color: #343434;
    }

    .error-asterisk {
        color: red;
        font-size: 10px;
        margin-left: 1px;
    }
    .no-error {
        color: #343434;
    }

    .dropdown-toggle {
        width: 95%;
        display: flex;
        justify-content: space-between;
        background-color: #ffffff;
        border: 1px solid #000000;
        color: #000000;
        position: relative;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown-toggle:hover {
        background-color: lightgray;
        border: 1px solid #000000;
        color: #000000;
    }

    input[type="text"],
    input[type="number"] {
        background-color: #ffffff;
        border: 1px solid #000000;
        color: #000000;
        padding: 8px 6px;
        border-radius: 8px;
        width: 95%;
    }

    input[type="number"] {
        padding-left: 18px !important;
    }

    textarea {
        width: 98%;
        resize: none;
        padding: 3px 8px;
        border: 1px solid;
        border-radius: 10px;
    }

    .number-field {
        position: relative;

        .dollar-sign {
            position: absolute;
            top: 9px;
            left: 8px;
        }
    }
}

.full-row-field {
    flex: 1 1 100% !important;
}

button[type="button"]:disabled{
    background-color: #ffffff;
    color:#000000;
    border-color: black;
    outline: none;
}

.add-to-list {
    padding: 5px 20px;
    width: 100%;
    display: inline-block;
}
.add-to-list:hover{
    background-color: lightgray;
    cursor: pointer;
}

.custom-search-ddn {
    position: static !important;
    ul {
        max-height: 200px;
        overflow-y: auto;
    }
}

.custom-menu-ddn {
    padding: 5px 20px;
    cursor: pointer;
}
.custom-menu-ddn:hover {
    background-color: lightgray;
}