@import "../../../assets/styles/mixins.scss";

.forgotPasswordText {
    font-size: 14px;
    line-height: 14px;
    color: var(--golden-color) !important;
    text-decoration: none;
}

.hr-with-text {
    height: 1px;
    background-color: var(--hr-color);

    span {
        font-size: 12px;
        line-height: 14px;
        background-color: var(--bg-color);
        top: -12px;
        background-color: var(--bg-color);
        width: 70px;
    }
}

.social-login {
    background-color: var(--social-login-bg);
    border-radius: 20px;
    height: 60px;
    width: 130px;
}

.reset-password-btns {
    .cancel.btn,
    .send.btn {
        width: 100%;
    }
    .cancel.btn {
        color: #CB9F47;
        border: 1px solid #CB9F47 !important;
        &:hover {
            background-color: #CB9F47 !important;
            color: #fff;
        }
    }
}

