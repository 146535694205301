@import '../../../../assets/styles/mixins.scss';

.btn,
.btn-accent,
.btn-primary,
.btn-white {
  transition: opacity 0.3s ease 0s;

  &:not([disabled]):hover {
    opacity: 0.9;
  }
}

.checkbox-radio-container{
  margin-bottom: 16px;;
  .checkbox-like-radios{
    legend{
      color: var(--color-text);
      font-size: 14px;
    }
    .form-check{
      height:48px;
    }
    .form-check-input{
      border-radius: 25px;
      background-color: var(--bg-color);
      border-color: var(--text-color);
    }
    .form-check-input:checked[type=checkbox]{
      background-image: url('../../../../assets/icons/tick.svg');
      background-color: var(--bg-color);
      border-color:#4737DA;
    }
    .form-check-input:focus{
      outline: none;
      box-shadow: none;
    }
    .form-check-label{
      font-size: 14px;
    }
  }
}

legend {
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  padding: 0px 4px;
  color: #A0A0A0;
  width: auto;
}

.form-field {
  border: 1px solid var(--button-primary-bg-plain);
  border-radius: 4px;
  background-color: inherit;
  margin-bottom: 24px;

  &.field-phone {
    .input-group-text {
      border: 0;
      background-color: transparent;
      color: var(--button-primary-bg-plain);
      padding-left: 1em;
      padding-right: 1em;
      position: relative;

      &::before {
        background-color: var(--button-primary-bg-plain);
        content: '';
        display: block;
        height: 60%;
        position: absolute;
        right: 1px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
      }
    }
  }

  &.disabled{
    color: var(--disabled-text-color);
    input{
      opacity: 0.6;
    }
  }

  &.min-h-60px{
    min-height: 60px;
  }
  
  &.textarea {
    border-radius: 1rem;
    textarea {
      border: none;
      font-size: 16px;
      font-weight: 300;
      height: 12rem;
      outline: none;
      padding: 1rem 0;
      width: calc(100% - 32px);
      background-color: var(--bg-color);
    }
  }

  
  input {
    background-color: transparent;
    border: none;
    border-radius: 24px;
    font-size: 12px;
    font-weight: 300;
    height: 34px;
    outline: none;
    padding: 4px 4px 2px;
    width: 100%;
    color: var(--color-text);
    gap: 4px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--color-text) !important;
    font-family: inherit;
  }
}

.button-golden {
  width: 100%;
  /* width: 327px; */
  height: 38px;
  background: var(--color-text-light) !important;
  border-radius: 8px;
  outline: none;
  border: none;
  margin-top: 20px;
}

.date-range-picker{

  height:57px;
  border-radius: 50px;
  overflow: hidden;
  .ant-picker{
    border:none;
    height: 100%;
    outline: none;
    box-shadow: none;
  }

}

.ant-picker-range-arrow{
  display: none !important;
}

.ant-picker-date-range-wrapper{
  border-radius:25px;
  box-shadow: var(--white-control-dropshadow);
  overflow: hidden;
}



@media (max-width: 480px) {
    .ant-calendar-range {
        width: 320px;
    }
    .ant-calendar-range-part {
        width: 100%;
    }
    .ant-picker-panel-container
    {
      .ant-picker-panels{
        // flex-wrap: wrap;
        flex-direction: column;
        margin: 0 auto;
      }

    } 
}