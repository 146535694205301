.default-variant-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    img {
        height: 100px;
        width: 100px;
        margin-right: 15px;
    }
}

.saved-iamge-heading {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
}