@import "../../../assets/styles/mixins.scss";

.order-popup-modal {
    max-width: 90vw !important;

    .modal-content {
        min-height: 90vh !important;

        .modal-header button {
            padding: 20px;
            transform: scale(0.8);
        }
    }
}

.order-popup-invoice-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
        margin-left: 5px;
        cursor: pointer;
    }
    .send-disable {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.client-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #A0A0A0;
    margin-bottom: 10px;
}

.customer-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #343434;
    padding: 3px 0;
}

.customer-address {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #343434;
    padding: 3px 0;
}

.invoice-btn-container {
    justify-content: flex-end;
    display: flex;
    margin-right: 15px;
}

.products-heading div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #A0A0A0;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 15px;
}

.orders-table {
    position: relative;

    .show-total {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
    div {
        display: flex;
        justify-content: space-between;
        min-width: 140px;
    }

        span {
            margin-left: 15px;
            font-family: 'Archivo';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 12px;
            color: #343434;
        }
    }
}

.orders-table .TableScrollbar {
    border: 1px solid lightgray !important;
    padding: 0;
    max-height: 35vh;
    padding-bottom: 40px;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.order-notes-section {
    .notes-heading {
        margin-top: 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #A0A0A0;
    }

    .input-box {
        padding: 4px;
        height: 64px;

        .notes-input {
            height: inherit;
            width: inherit;
            border: 1px solid lightgray;
            border-radius: 4px;
            resize: none;
        }
        .notes-input:focus-visible{
            outline: none;
        }

        .notes-input::placeholder {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #CACACA;
            position: absolute;
            padding: 4px;
        }
    }
}

.order-action-buttons {
    padding-top: 35px;

    div {
        display: flex;
        justify-content: flex-end;

        .print-receipt {
            color: var(--golden-color);
            border-color: var(--golden-color);
        }

        .order-pickup {
            color: var(--color-text-dark);
            background-color: var(--color-text-light);
        }

        button {
            width: 200px;
            margin-left: 30px;
        }
    }
}

.store-admin-orders {
    .color-PD {
        color: #FB8D8D;
    }
    .color-CO {
        color: #57B87D;
    }
    .color-RP {
        color: #CB9F47;
    }
}

.order-indicator {
    height: 15px;
    width: 15px;
    display: inline-block;
    margin-right: 7px;
    border-radius: 10px;
    background-color: gray;
    margin-bottom: -2px;
}

.color-PD {
    .order-indicator{
        background-color: #FB8D8D;
        color: black;
    }
}
.color-CO {
    .order-indicator{
        background-color: #57B87D;
        color: black;
    }
}
.color-RP {
    .order-indicator{
        background-color: #CB9F47;
        color: black;
    }
}
