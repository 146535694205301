@import "../../../assets/styles/mixins.scss";

.has-horzontal-scroll {
    overflow-x: auto;
}

.font-14 {
    font-size: 14px;
}

.font-10 {
    font-size: 10px;
}

.dashboard-main {
    .head {
        height: unset;

        .hello-user-card {
            .hello-user-card__info {
                span {
                    line-height: 14px;
                }
            }
        }

        .available-balance-head {
        }

        .balance-due {
            width: 200px;

            span {
                &.due-amount {
                    font-weight: 700;
                }
            }
        }

        .dashboard-options {
            bottom: -32px;

            .dashboard-legend {
                padding: 8px;
                height: 65px;
                width: 85px;
                border-radius: 25px;
                font-weight: 800;
                box-shadow: var(--legend-dropshadow);
            }
        }
    }

    .dashboard-info-card {
        border-radius: 25px;
        margin-left: 16px;
        height: auto;

        & > * {
            width: 260px;
        }

        .info-head {
            border: #1eb4ff 1px solid;
            border-radius: 17px 17px 0px 0px;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            padding-left: 16px;
        }

        .info {
            background: var(--var-blue-gradient-bg);
            height: 125px;
            border-radius: 0px 0px 14px 14px;
            height: 64px;
        }
    }
}

.table {
    background-color: var(--color-text-dark);
    border-radius: 24px;
    font-size: 12px;
    color: var(--color-text-light);
}

.table-responsive {
    border-radius: 24px;
}

.actions {
    display: flex;
    justify-content: space-between;

    .img,
    img {
        cursor: pointer;
        display: flex;
        height: 18px;
        margin-right: auto;
        width: 16px;

        .icon-wrap {
            margin-left: 4px;
        }
    }
}

.createIcon {
    background-color: var(--color-text-light) !important;
}

.searchbox .form-control {
    padding: 0.5rem 50px 0.5rem 2rem !important;
    background: white url("../../../assets/icons/search.svg") no-repeat !important;
    background-size: 15px !important;
    background-position: 6px !important;
    color: black !important;
    width: 327px !important;
}

.filter-container {
    display: flex;

    .dropdown {
        margin-left: 28px;
        margin-top: 20px;

        button {
            background-color: white;
            width: auto;
            min-width: 85px;
            padding-right: 25px;
            color: black;
            border-color: lightgray;
            height: 32px;
            margin-top: -2px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 12px;
            color: #343434;
        }

        button:active {
            color: white;
            background-color: black;
            border-color: white;
        }

        .dropdown-menu {
            height: 135px;
            max-width: 175px;
            a:first-child {
                color: gray;
            }
        }
    }

    .role-dropdown {
        position: relative;

        img {
            position: absolute;
            right: 0;
            top: 24px;
        }
    }
}

.save-btn {
    transform: scale(1.09);
    opacity: 0.4;
    cursor: pointer;
}
