.loader {
    border: 4px solid #eee;
    border-radius: 50%;
    border-top: 4px solid var(--color-text-light);
    width: 30px;
    height: 30px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }

  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .loader-body-fix {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: lightgray;
    opacity: 0.6;
    z-index: 2;
    pointer-events: stroke;
  }

  .display-none {
    display: none;
  }