.create-deal-wrapper {
    border-radius: 10px;
    height: 100%;
    background-color: white;
    padding: 15px;

    label {
        margin-bottom: 0 !important;
        color: #A0A0A0;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        text-transform: capitalize;
    }

    .create-deal-heading {
        color: #343434;
        font-size: 24px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .create-deal-row {
        margin-top: 10px;
    }

    .deal-message {
        display: flex;
        flex-direction: column;
        textarea {
            resize: none;
            border: 1px solid lightgray;
        }
    }

    .items-price {
        margin-top: 20px;

        .product-price-heading {
            color: #A0A0A0;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            text-transform: capitalize;
        }

        .product-price {
            color: #CACACA;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            text-transform: capitalize;
        }

        .number-of-items {
            label {
                color: #A0A0A0;
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                text-transform: capitalize;
            }

            .stock-qty-actions {
                width: 100%;
                height: 58% !important;
                padding-top: 5px;
                max-width: none;
                position: relative;

                .input-box {
                    width: 70%;

                    input {
                        width: 100%;
                        text-align: center;
                    }
                }

                .inc-icon {
                    position: absolute;
                    right: 0;
                }
            }
        }

        .deal-price {

            .input-field {
                position: relative;
                height: 29px;

                p {
                    position: absolute;
                    left: 5px;
                    top: 3px;
                }

                .deal-price-input {
                    max-width: 100%;
                    min-height: 28px;
                    border-radius: 6px;
                    padding-left: 15px;
                    border: 1px solid lightgray;
                }
            }
        }
    }

    .deal-date-wrapper {
        margin-top: 20px;
    }
}

.deals-date-picker {
    .react-datepicker-wrapper {
        width: 80%;

        input {
            height: 35px;
            width: inherit;
            border: none;
        }
    }
}

.deals-datepicker-wrapper {
    position: relative;
    border: 1px solid lightgray;
    border-radius: 5px;

    .calendar-image {
        position: absolute;
        top: 4px;
        right: 2px;
    }
}

.create-deal-row {
    .input-box {
        position: relative;

        .list {
            position: absolute;
            padding: 5px;
            box-shadow: 0px 3px 9px -1px rgba(0, 0, 0, 0.75);
            top: 60px;
            width: 100%;
            background-color: white;
            max-height: 200px;
            overflow-y: auto;
            z-index: 1;

            ul {
                padding: 0;
                list-style: none;

                li {
                    padding: 10px;
                }

                li:hover {
                    background-color: lightgray;
                    cursor: pointer;
                }
            }
        }
    }
}

.show-selected-product {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    input {
        flex-grow: 1;
        margin-right: 5px;
        padding: 4px;
        /* border-radius: 7px; */
        border: 1px solid goldenrod;
        outline: none;
        color: gray;
        font-weight: 500;
    }

    img {
        cursor: pointer;
    }
}

.submit-button-row {
    justify-content: flex-end;
    margin-top: 20px !important;

    button {
        width: auto;
    }
}

.deals-error-message {
    color: red;
    font-size: 11px;
}