.delete-popup-body {
    .store-name {
        color: #CB9F47;
        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
    }

    .store-admin-names,
    .store-address,
    .store-email {
        color: #343434;
        font-family: Archivo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }
}