.modal-content {
  border-radius: 32px !important;

  .modal-header {
    justify-content: center;
    border-bottom: none;

    .modal-title {
      font-weight: 600;
      margin-top: 40px;
    }

    .btn-close {
      position: absolute;
      top: 20px;
      right: 30px;
      width: 0px;
      height: 0px;
    }
  }

  .modal-body {
    padding: 0px 50px 50px 50px;

  }
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  padding: 0 !important;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  margin-top: 0px !important;

  #rfs-btn {
    height: 38px !important;
    margin-top: 6px !important;
  }
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #A0A0A0;
  margin-bottom: 0.5rem;
}

.location .form-control {
  padding: 0.5rem 2rem;
  background: white url("../../assets/icons/search.svg") no-repeat !important;
  background-size: 20px;
  background-position: 4px 6px !important;
  color: #CACACA !important;
  // &:focus {
  //     background-color: #E0EAE0;
  // }
}

.upload-photo {
  background-color: #1C321C;


  .drag-drop-text {
    color: #CDC7C7 !important;
    font-size: 12px;
    line-height: 14px;
  }

}

.takePhotoWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;


  .fileUploadField {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
  }
}

.preview {
  height: 64px;
  width: 64px;
  border-radius: 32px;

  img {
    height: 64px;
    width: 64px;
    border-radius: 32px;
  }
}

.locationbox {
  width: 100%;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #A0A0A0;
  font-size: 11px;
  margin-bottom: 0;
}

.location-wrapper {
  position: relative;
}

.location-required {
  position: absolute;
  color: #dc3545;
  font-size: 9px;
  bottom: 0;
}

.address-bar-error {
  div {
    input {
      border-color: #dc3545;
    }
  }
}