@import "../../../assets/styles/mixins.scss";

.forgotPasswordText {
    font-size: 14px;
    line-height: 14px;
    color: var(--golden-color) !important;
    text-decoration: none;
}

.hr-with-text {
    height: 1px;
    background-color: var(--hr-color);
    span {
        font-size: 12px;
        line-height: 14px;
        background-color: var(--bg-color);
        top: -12px;
        background-color: var(--bg-color);
        width: 70px;
    }
}

.social-login {
    background-color: var(--social-login-bg);
    border-radius: 20px;
    height: 60px;
    width: 130px;
}

.admin-input-password .eye-Regular {
    top: 2.15rem !important;
}