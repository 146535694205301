.past-deals-wrapper {

    .past-deals-header {
        display: flex;
        justify-content: space-between;
        .row {
            max-height: 60px;
            .searchbox {
                margin: 0;
                label {
                    display: none;
                }
            }
        }
    }

    .past-deal-heading {
        color: #343434;
        font-size: 24px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .past-deals-table {
        margin-top: 20px;
        .past-deals-list {
            max-height: inherit;
            thead {
                z-index: 1;
            }
        }
    }

    .past-deals-actions {
        input[type="checkbox"] {
            scale: 0.8;
        }
        .delete-icon {
            scale: 0.8;
        }
        .form-check-input:checked {
            background-color: #CB9F47;
            border-color: #CB9F47;
        }
    }
}