@import '../../../../assets/styles/mixins.scss';

.centered-container {
  align-items: center;
  display: flex;
  justify-content: center;

  > .row {
    @include mq($global-breakpoint-md) {
      flex-direction: row !important;
      flex-wrap: wrap;
    }
  }

  .half {

    @include mq($global-breakpoint-md) {
      position: relative;
      width: 350px !important;

      &:first-child::before {
        background-color: var(--color-text-light);
        content: '';
        display: block;
        height: 100%;
        opacity: 0.1;
        position: absolute;
        right: -1px;
        top: 0;
        width: 1px;
      }
    }
  }
  .whole {
    width: 100%;
  }
}
.dark-theme-inside .centered-container,
.has-gradient-bg .centered-container,
[data-theme="dark"] .centered-container {
  .half {
    @include mq($global-breakpoint-md) {
      &:first-child::before {
        background-color: var(--color-text-dark);
      }
    }
  }
}