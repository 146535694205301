.modal-content{
    border-radius: 32px !important;
.modal-header{
    justify-content: center;
    border-bottom: none;

    .modal-title{
        font-weight: 600;
        margin-top: 40px;
    }
    .btn-close{
        position: absolute;
        top: 20px;
        right: 30px;
        width: 0px;
        height: 0px;
    }
}
.modal-body{
    padding: 0px 50px 50px 50px;

}
}
.ReactFlagsSelect-module_selectBtn__19wW7{
padding: 0 !important;
}
.countryFlag{
    margin-top: 0 !important;
}
label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #A0A0A0;
    margin-bottom: 0.5rem;
    }
.title{
    font-size: 16px;
    font-weight: 600;
}
.name{
    font-size: 13px;
    font-weight: 500;
}
.userRole{
    color: var(--golden-color);
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
}
.cancelButton{
    background: white !important;
    color: var(--golden-color) !important;
border-color: var(--golden-color) !important;
    width: 100%;
    /* width: 327px; */
    height: 38px;
    border-radius: 8px;
    outline: none;
    border: none;
    margin-top: 20px;
}
