@import "../../../assets/styles/mixins.scss";

.message{
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #A0A0A0;
    margin-top: 8px;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.reply-container {
    display: flex;
    justify-content: flex-end;
    img {
        margin-top: 3px;
    }
}
.reply-text{
    text-align: right;
    font-size: 14px;
    color: var(--golden-color);
    font-weight: 600;
    margin-top: 24px;
    cursor: pointer;
    padding: 0 20px;
}
.subject{
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-light);
    margin-top: 24px
}
.mail{
    font-size: 13px;
    font-weight: 400;
    color: #A0A0A0;
    margin-top: 8px;
    margin-bottom:0;
}
.name{
    font-size: 14px;
    font-weight: 600;
    margin-left: 4px;
    color: var(--color-text-light);
    margin-bottom: 0
}
.image{
    height: 20px;
    width: 20px;
    border-radius: 10px
}
.apply-border-img {
    border: 1px solid;
}
.reply{
    width: 100%;
    border: 0 none #FFF;
    outline:none;
    font-size: 14px;
    overflow-y: auto;
    min-height: 80px;
}
.reply:focus{
    width: 100%;
        border: 0 none #FFF;
        // overflow: hidden;
        outline:none;
        font-size: 14px;

}
.reply-message{
    font-size: 14px;
    color: var(--golden-color);
    font-weight: 400;
    margin-top: 24px;
    margin-left: 24px;
}
.vr{
    width: 10px !important;
    color: var(--golden-color);
}
.no-margin {
    margin: 0;
    max-width: 100%;
}
.message-text{
    font-size: 12px;
    color: var(--golden-color);
    font-weight: 400;
    margin-top: 24px;
}
.h-max-80vh{
    max-height: 82vh !important;
}
.overflow-y-scroll{
    overflow-y: scroll;
}
.selected-card{
    background-color: whitesmoke;
}
.reply-text-field{
    background-color: white;
    border-radius: 8px;
    max-height: 62vh;
}
.disabled-reply-icon {
    opacity: 0.4;
    pointer-events: none;
}
.email-feedback-container {
    padding: 0 25px;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}