@import "./variables.scss";

@mixin grad-theme-primary() {
  background: var(--bg-color);
}



/* mobile-first media query */
@mixin mq($value) {
  @if (type-of($value) != number) {
    @error "Sorry, but `#{$value}` is not a valid argument for the mq() mixin.";
  } @else if (unitless($value)) {
    $value: $value + 'px';
  }
  @media only screen and (min-width: $value) {
    @content;
  }
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}