.create-product-wrapper {
    height: 100%;
    padding: 25px;

    .products-heading {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        color: #343434;
    }

    .main-panel {
        background: #FFFFFF;
        border: 1px solid #EFEFEF;
        margin-top: 10px;
        // height: 85%;
        height: 86vh;
        border-radius: 20px;
        padding: 0px 20px 0px 20px;
        display: flex;

        .left {
            width: 50%;
            height: 100%;
            border-right: 1px solid lightgray;
            padding-top: 20px;
            padding-bottom: 20px;

            .heading-section {
                span:first-child {
                    display: flex;
                    flex-direction: column;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 24px;
                    display: flex;
                    color: #343434;
                }

                span:nth-child(2) {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #A0A0A0;
                }
            }

            .field-section {
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                height: 82%;
                margin-top: 15px;

                .category,
                .details,
                .compatible-products {
                    background: #EFEFEF;
                    width: 90%;
                    padding: 7px;
                    border-radius: 5px;
                    margin-bottom: 25px;
                    position: relative;

                    .fields {
                        background: #ffffff;
                        padding: 10px;
                        display: flex;
                        flex-flow: wrap;
                        margin-top: 5px;
                        padding-top: 15px;

                        .cp-sp {
                            width: 100%;
                            display: flex;
                        }

                        .profit-section {
                            margin-top: 10px;

                            .profit-heading,
                            .profit-value {
                                font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 16px;
                                align-items: center;
                                color: #A0A0A0;
                            }

                            .profit-value {
                                font-weight: 800;
                                font-size: 16px;
                                color: #343434;
                            }
                        }
                    }


                    img {
                        position: absolute;
                        right: 14px;
                        top: 16px;
                        cursor: pointer;
                    }

                    .category-header,
                    .details-header,
                    .compatible-products-header {
                        cursor: pointer;
                        width: 431px;
                        height: 16px;
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        color: #343434;
                    }
                }
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
                padding: 0px 65px;

                .cancel-btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 24px;
                    gap: 8px;
                    background: #343434;
                    border-radius: 8px;
                    color: #ffffff;
                }

                .submit-btn {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 24px;
                    gap: 8px;
                    background: #DBBE82;
                    border-radius: 8px;
                    margin-left: 15px;
                }
            }
        }

        .right {
            width: 50%;
            height: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 10px;

            .product {
                background: #EFEFEF;
                width: 100%;
                padding: 7px;
                border-radius: 5px;
                margin-bottom: 25px;
                position: relative;
                height: 100%;
                overflow-y: auto;

                .fields {
                    background: #ffffff;
                    padding: 10px;
                    display: flex;
                    margin-top: 5px;
                }

                .product-header {
                    position: relative;
                    cursor: pointer;

                    img {
                        position: absolute;
                        right: 14px;
                        top: 10px;
                        cursor: pointer;
                    }
                }



            }

            .add-variant {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: #CB9F47;
                padding: 13px 8px;
                background: white;
                cursor: pointer;
            }
        }
    }
}


.revert-image {
    transform: rotate(180deg);
}

.variant-fields {
    position: relative;
}

.variant-action {
    position: absolute;
    right: 30px;
    top: 3px;
    display: flex;
    justify-content: space-between;

    .variant-save-btn {
        opacity: 0.2;
    }

    .variant-save-btn:hover {
        opacity: 1;
    }

    img {
        margin-left: 5px;
        cursor: pointer;
    }

    img:hover {
        filter: invert(62%) sepia(82%) saturate(318%) hue-rotate(2deg) brightness(90%) contrast(87%);
    }
}

.hidden-image {
    display: none;
}

.custom-search-ddn {
    transform: initial !important;
}

.variant-image-wrapper {
    position: relative;
    height: 70px;
    width: 70px;
    border-radius: 33px;
    border: 1px solid lightgray;
    margin-right: 10px;
    min-width: 70px;
    // min-height: 50px;

    // #file-upload-button{
    //     width: inherit;
    //     padding: 0;
    //     margin: 0;
    // }
    input[type="file"]::-webkit-file-upload-button {
        margin: 0;
        width: inherit;
        padding: 0;
    }

    .add-icon {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

.image-insert {
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    top: 0;
    overflow: hidden;
    position: absolute;
}

.preview-image-variant {
    height: inherit;
    width: inherit;
}

.preview-image-variant-other {
    height: 30px;
    width: 30px;
    border: 1px solid lightgray;
}

.other-variants-wrapper {
    background: white;
    padding: 3px 12px;
    display: flex;
}

.notification-box {
    text-align: center;
    width: 50%;
    margin: auto;
    background: #DBBE82;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    border-radius: 5px;
}

.no-variant-right {
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.variant-save-btn-no-variation {
    height: 24px;
    width: 24px;
}

.no-variation-action {
    right: 20px;
    top: 30px;
    opacity: 0.2;
}

.no-variation-action:hover {
    right: 20px;
    top: 30px;
    opacity: 1;
}

.disabled-text {
    opacity: 0.5;
    pointer-events: none;
}

.compatible-products-wrapper {
    width: 100%;

    input {
        border-color: #CB9F47;
    }

    .input-box {
        position: relative;

        .list {
            position: absolute;
            padding: 5px;
            box-shadow: 0px 3px 9px -1px rgba(0, 0, 0, 0.75);
            top: 60px;
            width: 100%;
            background-color: white;
            max-height: 200px;
            overflow-y: auto;
            z-index: 1;

            ul {
                padding: 0;
                list-style: none;

                li {
                    padding: 10px;
                }

                li:hover {
                    background-color: lightgray;
                    cursor: pointer;
                }
            }
        }
    }

    .chips-area {
        display: flex;
        flex-wrap: wrap;

        .product-chip {
            padding: 5px;
            border: 1px solid lightgray;
            border-radius: 8px;
            margin-right: 10px;
            margin-bottom: 8px;
            position: relative;

            .cross-icon {
                margin-left: 6px;
                margin-right: 4px;
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 6px;
            }
        }
    }
}